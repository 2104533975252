import { Channel } from "@anycable/web";

export default class BaseChannel extends Channel {
  receive(data) {
    super.receive(data);
    const { event } = data

    if (event === 'session_expired') {
      window.location.reload
    }
  }

  connected(){
    super.connected();
  }
}
