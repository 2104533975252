import { createCable } from "@anycable/web";

const buildWebSocketUrl = () => {
  if (window.location.host === "localhost:3000"){
    return `ws://localhost:3334/cable?referrer=${window.location.pathname}`;
  } else {
    return `wss://${window.location.host}/cable?referrer=${window.location.pathname}`;
  }
};

const cable = createCable(buildWebSocketUrl());

export default cable;
