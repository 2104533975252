import BaseChannel from "./base_channel";

export default class InitialPaymentEventChannel extends BaseChannel {
  static identifier = "InitialPaymentEventChannel";

  receive(data) {
    super.receive(data);
    const { event, message } = data;
    this.emit(event, message);
  }
}
