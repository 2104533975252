/**
 * Simple pubsub pattern for coordinating between controllers
 */

const SUBSCRIBER_REGISTRY = {};

/**
 * Publishes event
 *
 * @param {string} eventName - event to publish
 * @param {Object} data - data bag to act on as part of the event, shape varies
 */
export function publish(eventName, data) {
  if (!SUBSCRIBER_REGISTRY[eventName]) {
    return;
  }

  SUBSCRIBER_REGISTRY[eventName].forEach((callback) => callback(data));
}

/**
 * Subscribes to event
 *
 * @param {string} eventName - name of the event to subscribe to
 * @param {function} callback - function to call on event publish
 */
export function subscribe(eventName, callback) {
  if (!SUBSCRIBER_REGISTRY[eventName]) {
    SUBSCRIBER_REGISTRY[eventName] = [];
  }

  SUBSCRIBER_REGISTRY[eventName].push(callback);
}

/**
 * Unsubscribes from event
 *
 * @param {string} eventName - name of the event subscribed to
 * @param {function} callback - function to unsubscribe from
 */
export function unsubscribe(eventName, callback) {
  if (!SUBSCRIBER_REGISTRY[eventName]) {
    return;
  }

  SUBSCRIBER_REGISTRY[eventName] = SUBSCRIBER_REGISTRY[eventName].filter((f) => f.toString() != callback.toString());
}
