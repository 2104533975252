import { I18n } from "i18n-js";
import translations from "~/translations.json";

const i18n = new I18n();
const defaultLocale = "en.leases";
const userLocale = document.documentElement.lang;

i18n.store(translations);
i18n.defaultLocale = defaultLocale;
i18n.enableFallback = true;
i18n.locale = userLocale.substring(0, 2) === "es" ? "es.leases" : defaultLocale;

window.acima = window.acima || {};
window.acima.i18n = i18n;
